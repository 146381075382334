/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'

import GlobalModel from "../../../../models/GlobalModel";

type Props = {
  setFieldValue: any
}

const InitialSocietyForm: React.FC<Props> = ({setFieldValue}) => {

  const [countries, setCountries] = useState([])
  const getCountries = async () => {
    let dataModel = new GlobalModel()
    const {data} = await dataModel.getCountriesData({per_page: 'all'})
    if (data.type === "success") {
      return data.result.data
    } else {
      return []
    }
  }

  useEffect(() => {
    let isCancelled = false;
    getCountries().then((response: any) => {
      if (!isCancelled)
        setCountries(prevState => response)
    })
    return () => {
      isCancelled = true
    };
  }, [])

  return (
    <>
      <div className='w-100'>
        <div className='pb-5 pb-lg-10'>
          <h2 className='fw-bolder fs-1 text-dark'>
            Informations de la société
          </h2>
          <div className='fw-bold fs-6 text-gray-400'>
            Renseigner les informations de l'entreprise
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Raison sociale</span>
          </label>
          
          <Field name='society_social_reason' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='society_social_reason' />
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center form-label'>
              <span className='required'>Pays</span>
            </label>

            <Field as='select' name='society_country_id' className='form-select form-select-lg form-select-solid'>
              <option>-- Sélectionnez le pays --</option>
              {
                countries && countries.map((option, optionIndex) => {
                  return (
                    <option key={'country' + optionIndex}
                      value={option['country_id']}>{option['name']}
                    </option>
                  )
                })
              }
            </Field>
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='society_country_id' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label className='d-flex align-items-center form-label'>
              <span className='required'>Ville</span>
            </label>

            <Field name='society_city'placeholder='ex : Mohammedia' className='form-control form-control-lg form-control-solid' />
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='society_city' />
            </div>
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center form-label'>
              <span className=''>N° de téléphone</span>
            </label>

            <Field name='society_phone' className='form-control form-control-lg form-control-solid' />
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='society_phone' />
            </div>
          </div>

          <div className='col-lg-6'>
            <label className='d-flex align-items-center form-label'>
              <span className='required'>Adresse e-mail</span>
            </label>

            <Field type='email' name='society_email' className='form-control form-control-lg form-control-solid' />
            <div className='fv-plugins-message-container text-danger'>
              <ErrorMessage name='society_email' />
            </div>
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center form-label'>
            <span className=''>Joindre le logo de l'entreprise</span>
          </label>
          
          <Field name='society_logo'
            render={() => (
              <input type='file' name='society_logo'
                className='form-control form-control-lg form-control-solid'
                onChange={(event) => {
                  // @ts-ignore
                  setFieldValue("society_logo", event.currentTarget.files[0])
                }}
              />
            )}       
          />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='society_logo' />
          </div>
        </div>
      </div>
    </>
  )
}

export {InitialSocietyForm}
