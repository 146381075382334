import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {useDispatch} from "react-redux";
import {actions as authActions} from "../AuthRedux";
import {actions as appActions} from "../../../setup/redux/AppRedux"

import {createInitialSchemas, ICreateAccount, inits} from './InitialSchemasWizardHelper'
import {InitialPersonForm} from './steps/InitialPersonForm'
import {InitialSocietyForm} from './steps/InitialSocietyForm'
import {InitialDepartmentForm} from './steps/InitialDepartmentForm'
import {InitialDomaineForm} from './steps/InitialDomaineForm'
import {InitialCompleted} from './steps/InitialCompleted'

import AuthModel from "../../../models/AuthModel";

const InitialView: FC = () => {
    const dispatch = useDispatch()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(createInitialSchemas[0])
    const [initValues] = useState<ICreateAccount>(inits)

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
        setCurrentSchema(createInitialSchemas[stepper.current.currentStepIndex - 1])
    }

    const submitStep = (values: ICreateAccount, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }

        setCurrentSchema(createInitialSchemas[stepper.current.currentStepIndex])

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            stepper.current.goNext()
        } else {
            // console.log(values)
            // stepper.current.goto(1)
            // actions.resetForm()
            setTimeout(() => {
                const auth = new AuthModel()
                auth.initLogin(values)
                    .then(async (response) => {
                        // console.log(response.data)
                        if (response.data.type == "success") {
                            await dispatch(authActions.authSuccess(response.data.result))
                            await dispatch(appActions.saveCurrency(response.data.result.settings.currency))
                            await dispatch(appActions.saveCurrentApp('GP'))

                            await dispatch(appActions.saveCurrentDepartment({
                                cdi: response.data.result.department.id,
                                cdiDesign: response.data.result.department.design_dep
                            }))
                        } else {
                            console.log(response.data)
                        }
                    })
                    .catch(() => {
                        //
                    })
            }, 1000)
        }

    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef])

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div ref={stepperRef} id='kt_create_account_stepper' className='stepper stepper-links d-flex flex-column'>
                        <div className='stepper-nav mb-10'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Compte</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Société</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Département</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Domaine</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <h3 className='stepper-title'>Terminé</h3>
                            </div>
                        </div>

                        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                        {({setFieldValue}) => (
                            <Form id='kt_create_account_form' className='w-100 mx-auto' noValidate>
                                <div data-kt-stepper-element='content' className='current'>
                                    <InitialPersonForm />
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <InitialSocietyForm setFieldValue={setFieldValue} />
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <InitialDepartmentForm />
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <InitialDomaineForm />
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <InitialCompleted />
                                </div>

                                <div className='d-flex flex-stack pt-15'>
                                    <div className='mr-2'>
                                        <button onClick={prevStep} type='button' className='btn btn-lg btn-light-orange me-3' data-kt-stepper-action='previous'>
                                            <KTSVG path='/media/icons/duotone/Navigation/Left-2.svg' className='svg-icon-4 me-1' />
                                            Précédent
                                        </button>
                                    </div>

                                    <div>
                                        <button type='submit' className='btn btn-lg btn-orange me-3'>
                                            <span className='indicator-label'>
                                                {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! - 1 && 'Suivant'}
                                                {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! - 1 && 'Enregistrer'}
                                                <KTSVG path='/media/icons/duotone/Navigation/Right-2.svg' className='svg-icon-3 ms-2 me-0' />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export {InitialView}
