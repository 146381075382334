/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {CheckAccess} from '../../../../app/Utilities';

const QuickLinks: FC = () => (
  <div className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px' data-kt-menu='true'>
    <div className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/defaults/pattern-4.jpg')}')`}}>
      <h3 className='fw-bold text-white text-uppercase mb-3'>
        Modules Smart Sentino
      </h3>
      <span className='badge bg-orange py-2 px-3'>
        Version 2.0
      </span>
    </div>

    <div className='row g-0'>
      {
        <div className='col'>
          <Link to={'/instrument/dashboard'} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'>
            <KTSVG path='/media/icons/duotone/Home/Home.svg' className='svg-icon-3x svg-icon-primary mb-2'/>
            <span className='fs-5 fw-bold text-gray-900 text-center text-uppercase mb-0'>
              Tableau de bord
            </span>
          </Link>
        </div>
      }

      {
        CheckAccess('material-access') &&
        <div className='col'>
          <Link to={'/instrument/materials/lists'} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'>
            <KTSVG path='/media/icons/duotone/Tools/Tools.svg' className='svg-icon-3x svg-icon-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-900 text-center text-uppercase mb-0'>
              Liste des matériels
            </span>
          </Link>
        </div>
      }

      {
        CheckAccess('outback-access') &&
        <div className='col'>
          <Link to={'/instrument/materials/out-back'} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
            <KTSVG path='/media/icons/duotone/Navigation/Exchange.svg' className='svg-icon-3x svg-icon-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-900 text-center text-uppercase mb-0'>
              Entrée/Sortie matériels
            </span>
          </Link>
        </div>
      }

      {
        CheckAccess('raccordement-access') &&
        <div className='col'>
          <Link to={'/instrument/raccordements'} className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <KTSVG path='/media/icons/duotone/Design/Bezier-curve.svg' className='svg-icon-3x svg-icon-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-900 text-center text-uppercase mb-0'>
              Suivi de raccordement
            </span>
          </Link>
        </div>
      }
    </div>

    {/* <div className='py-2 text-center border-top'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All 
        <KTSVG path='/media/icons/duotone/Navigation/Right-2.svg' className='svg-icon-5' />
      </Link>
    </div> */}
  </div>
)

export {QuickLinks}
